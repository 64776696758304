import downloadFromRustore from "./assets/logo-color-light.svg";

export default function Footer() {
  return(
    <div className="p-6 border-t border-t-zinc-100 md:flex justify-between items-start">
      <div className="text-[0.8rem] text-zinc-700 mb-4">
        <div>
          &copy; {new Date().getFullYear()}{' '}
          <a href="https://edwardcode.net" target="_blank" className="underline">edwardcode.net</a>
          {' & '}
          <a href="https://dpkgsoft.com" target="_blank" className="underline">dpkgsoft international limited</a>
        </div>
        <div>Сделано студентами ДВФУ.</div>
        <div>
          При возникновении вопросов свяжитесь с нами по эл. почте{' '}
          <a href="mailto:support@dpkgsoft.com" className="underline">support@dpkgsoft.com</a>
        </div>
        <div className="mt-2 flex justify-start gap-3">
          <img src={downloadFromRustore} alt="Download from Rustore" className="block cursor-pointer h-12"
               onClick={() => window.open("https://www.rustore.ru/catalog/app/net.edwardcode.vuz.schedule", "_blank")}/>
        </div>
      </div>
      <img src="https://cdn.dpkgsoft.com/images/dpkgsoft/dpkgsoft.svg" alt="DpkgSoft Logo" className="h-7"/>
    </div>
  );
}
