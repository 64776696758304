import {Dialog, Transition} from "@headlessui/react";
import React from "react";
import {
  ArrowTopRightOnSquareIcon,
  CalendarIcon,
  ClockIcon, FlagIcon,
  HeartIcon,
  PencilIcon,
  SignalIcon,
  UserIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

export default function PairModal({ pair, pairNum, open, setOpen }) {
  let timePeriod = ["0:00", "0:00"];
  switch (pairNum) {
    case 1: timePeriod = ["8:30", "10:00"]; break;
    case 2: timePeriod = ["10:10", "11:40"]; break;
    case 3: timePeriod = ["11:50", "13:20"]; break;
    case 4: timePeriod = ["13:30", "15:00"]; break;
    case 5: timePeriod = ["15:10", "16:40"]; break;
    case 6: timePeriod = ["16:50", "18:20"]; break;
    case 7: timePeriod = ["18:30", "20:00"]; break;
    case 8: timePeriod = ["20:10", "21:40"]; break;
  }

  const goToFEFUMap = () => {
    toast.promise(
      new Promise((a, b) => {
        const audience = pair.facility.replaceAll("(", " ").split(" ")[0];
        fetch(`/api/search_fefumap?audience=${audience}`)
          .then(d => d.json())
          .then(d => {
            if (d.data) {
              window.open(`https://fefumap.com/#${d.data}`, "_blank");
              a();
            } else {
              b();
            }
          })
          .catch(b);
      }),
      {
        loading: "Загрузка...",
        success: "Успешно, перенаправление...",
        error: "Ошибка. Возможно, аудитория не найдена на карте."
      }
    )
  };

  if (!open) return null;

  return(
    <Transition
      as={React.Fragment}
      appear
      show
    >
      <Dialog open onClose={() => setOpen(false)}>
        <Dialog.Overlay
          className={`fixed inset-0 transition bg-zinc-400/50 backdrop-blur opacity-100 z-50`} />
          <div className="fixed inset-0 overflow-hidden z-50">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                <Transition.Child
                  as={React.Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Dialog.Panel
                    className={`pointer-events-auto relative w-screen p-3 md:max-w-md md:p-6 translate-x-0`}>
                    <div
                      className="relative z-30 h-full overflow-y-auto [--scrollbar-size:theme(width.3)] transform shadow-xl transition-all rounded-2xl bg-white ring-1 ring-zinc-100">
                      <div
                        className="bg-white sticky top-0 z-20 grid w-full grid-flow-col items-center justify-between gap-6 px-5 py-4 text-lg">
                        <Dialog.Title className={`font-manrope font-semibold`}>Пара</Dialog.Title>
                        <button
                          onClick={() => setOpen(false)}
                          className="-m-3 grid h-10 w-10 place-items-center rounded-full transition hover:bg-zinc-200/25 dark:hover:bg-zinc-600/20">
                          <XMarkIcon className={`h-6 w-6`}/>
                        </button>
                      </div>
                      <div>
                        <div className="mt-6 grid overflow-hidden">
                          <div className="grid gap-6 p-5 pt-0">
                            <h1 className="font-manrope font-bold text-xl">{pair.name}</h1>
                            <div className="grid gap-2 text-sm">
                              <div className="grid grid-cols-[auto_1fr] gap-2 items-center">
                                <CalendarIcon className={`w-4 h-4`}/>
                                <span>Дата: {pair.date.split("-")[2]}.{pair.date.split("-")[1]}.{pair.date.split("-")[0]}</span>
                              </div>
                              <div className="grid grid-cols-[auto_1fr] gap-2 items-center">
                                <ClockIcon className={`w-4 h-4`}/>
                                <span>Начинается в {timePeriod[0]}</span>
                              </div>
                              <div className="grid grid-cols-[auto_1fr] gap-2 items-center">
                                <HeartIcon className={`w-4 h-4`}/>
                                <span>Заканчивается в {timePeriod[1]}</span>
                              </div>
                              <div className="grid grid-cols-[auto_1fr] gap-2 items-center">
                                <UserIcon className={`w-4 h-4`}/>
                                <span>Ведёт {(pair.teacher !== "" && pair.teacher !== "БезИмени А А") ? pair.teacher : "неизвестный преподаватель"}</span>
                              </div>
                              <div className="grid grid-cols-[auto_1fr] gap-2 items-center">
                                {pair.type === "Лекционные занятия" ? (
                                  <SignalIcon className="w-4 h-4"/>
                                ) : (
                                  <PencilIcon className="w-4 h-4"/>
                                )}
                                <span>{pair.type === "Лекционные занятия" ? "Лекция" : pair.type}</span>
                              </div>
                              {pair.facility !== "" && (
                                <>
                                  <div className="grid grid-cols-[auto_1fr] gap-2 items-center">
                                    <FlagIcon className={`w-4 h-4`}/>
                                    <span>Аудитория {pair.facility}</span>
                                  </div>
                                  <div className="grid grid-cols-[auto_1fr] gap-2 items-center cursor-pointer" onClick={() => goToFEFUMap()}>
                                    <ArrowTopRightOnSquareIcon className={`w-4 h-4`}/>
                                    <span className="text-blue-800">Посмотреть на FEFU Map</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
      </Dialog>
    </Transition>
  );
}
