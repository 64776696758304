export function getMonday(d) {
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
}
export function getSaturday(d) {
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -1 : 6);
  return new Date(d.setDate(diff));
}
export function getWeekNumber(d) {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 8, 18));
  const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  return [d.getUTCFullYear(), weekNo];
}

export const strToDate = (s) => {
  const partsGlobal = s.split(" ");
  const partsDate = partsGlobal[0].split("-");
  const partsTime = partsGlobal[1].split(":");

  return new Date(
    parseInt(partsDate[0]),
    parseInt(partsDate[1]) - 1,
    parseInt(partsDate[2]),
    parseInt(partsTime[0]),
    parseInt(partsTime[1]),
    parseInt(partsTime[2]),
  );
}
