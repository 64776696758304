import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/24/outline";

export default function DaySwitcher({currentDay, setCurrentDay}) {
  const getDays = (cur) => {
    switch (cur) {
      case "mon": return ["sat", "tue"];
      case "tue": return ["mon", "wed"];
      case "wed": return ["tue", "thu"];
      case "thu": return ["wed", "fri"];
      case "fri": return ["thu", "sat"];
      case "sat": return ["fri", "mon"];
    }
  }

  return(
    <div className="px-8 flex justify-between lg:hidden">
      {currentDay !== "mon" ? (
        <button className="grid grid-cols-[auto_1fr] text-sm items-center gap-2" onClick={() => setCurrentDay(getDays(currentDay)[0])}>
          <ArrowLeftIcon className="w-4 h-4"/>
          Пред. день
        </button>
      ) : (
        <div />
      )}
      {currentDay !== "sat" ? (
        <button className="grid grid-cols-[auto_1fr] text-sm items-center gap-2" onClick={() => setCurrentDay(getDays(currentDay)[1])}>
          След. день
          <ArrowRightIcon className="w-4 h-4"/>
        </button>
      ) : (
        <div />
      )}
    </div>
  );
}
