import Header from "./Header";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import animationLoading from "./assets/animation_loading.lottie";

import logo from "./assets/logo-icon.png";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import Footer from "./Footer";

export default function MainLayout() {
  const [ user, setUser ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const navigate = useNavigate();

  const updateUser = () => {
    fetch(`/api/user`)
      .then(d => d.json())
      .then(d => {
        if (d.guid) {
          setUser(d);
          setLoading(false);
        } else {
          navigate(`/login`);
        }
      })
  };

  useEffect(() => {
    updateUser();
    window.updateUser = updateUser;
  }, []);

  if (loading) {
    return(
      <div className="h-[100vh] flex flex-col justify-center items-center">
        <div className="grid gap-4">
          <DotLottieReact
            src={animationLoading}
            autoplay
            loop
            className="block h-[240px] w-[240px] mx-auto"
          />
          <div className="font-manrope text-2xl mx-auto font-semibold">Загрузка</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header user={user} />
      <div className="mt-5">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}
