import logoImage from "./assets/logo.png";
import logoIcon from "./assets/logo-icon.png";
import {Menu, Transition} from "@headlessui/react";
import {
  ArrowLeftStartOnRectangleIcon,
  Cog6ToothIcon,
  FunnelIcon,
  UserIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import React from "react";
import UserProfileMenuItem from "./UserProfileMenuItem";
import {useNavigate} from "react-router-dom";

export default function Header({ user }) {
  const navigate = useNavigate();
  return(
    <header className="sticky top-0 bg-white p-4 grid grid-cols-[1fr_auto] items-center shadow">
      <img src={logoImage} alt="Logo" className="h-8 hidden sm:block cursor-pointer" onClick={() => navigate("/schedule")} />
      <img src={logoIcon} alt="Logo" className="h-8 block sm:hidden cursor-pointer" onClick={() => navigate("/schedule")}/>
      <Menu as="div" className="relative">
        {({open}) => (
          <>
            <Menu.Button className="grid transition hover:opacity-90" title="Профиль">
              <div className="relative grid grid-cols-[auto_1fr] gap-1 items-center">
                <UserIcon className="w-5 h-5" />
                <span className="text-sm">{user.name.split(" ")[0]} {user.name.split(" ")[1].substring(0, 1)}.</span>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute right-0 mt-3 grid w-64 origin-top-right select-none rounded-xl bg-white p-3 shadow-lg ring-1 ring-inset ring-zinc-100">
                <div className="grid px-4 py-3">
                  <div className="grid items-center justify-start gap-1">
                    <div className="font-manrope truncate text-lg font-semibold">
                      {user.name}
                    </div>
                    <div className="truncate text-sm grid grid-cols-[auto_1fr] gap-1 items-center">
                      <UsersIcon className="h-4 w-4"/> {user.academic_group.name}
                    </div>
                    <div className="truncate text-sm grid grid-cols-[auto_1fr] gap-1 items-center">
                      <FunnelIcon className="h-4 w-4"/> {user.subgroup !== 0 ? `Подгруппа ${user.subgroup}` : "Без подгруппы"}
                    </div>
                  </div>
                </div>
                <Menu.Items className="focus:outline-none">
                  <UserProfileMenuItem text="Настройки" icon={<Cog6ToothIcon className="w-5 h-5"/>} href="/schedule/settings" />
                  <UserProfileMenuItem text="Выход" icon={<ArrowLeftStartOnRectangleIcon className="w-5 h-5" />} href="/schedule/logout" />
                </Menu.Items>
              </div>
            </Transition>
          </>
        )}
      </Menu>
    </header>
  );
}
