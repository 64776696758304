import {useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import MainLayout from "./MainLayout";
import Schedule from "./Schedule";
import LoginPage from "./LoginPage";
import {Toaster} from "react-hot-toast";
import LogoutPage from "./LogoutPage";
import Settings from "./Settings";

function App() {
  useEffect(() => {
    document.body.parentElement.classList.add('h-full');
    document.body.classList.add("h-full");
  }, []);

  return (
    <>
      <Routes>
        <Route path="/schedule" element={<MainLayout />}>
          <Route index element={<Schedule />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<Navigate to={"/schedule"} />} />
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
