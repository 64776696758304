import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import animationError from "./assets/animation_some_problems.lottie";
import animationLoading from "./assets/animation_loading.lottie";
import animationNeedSetup from "./assets/animation_need_setup.lottie";
import {getMonday, getSaturday, getWeekNumber} from "./utils";
import Pair from "./Pair";
import DaySwitcher from "./DaySwitcher";

export default function Schedule() {

  const [loading, setLoading ] = useState(true);
  const [error, setError] = useState(null);
  const [ schedule, setSchedule ] = useState([
    [null,null,null,null,null,null],
    [null,null,null,null,null,null],
    [null,null,null,null,null,null],
    [null,null,null,null,null,null],
    [null,null,null,null,null,null],
    [null,null,null,null,null,null],
    [null,null,null,null,null,null],
    [null,null,null,null,null,null],
  ]);
  const [ selectedDay, setSelectedDay ] = useState('');
  const navigate = useNavigate();

  function getNextDayOfTheWeek(dayName, excludeToday = true, refDate = new Date()) {
    const dayOfWeek = ["sun","mon","tue","wed","thu","fri","sat"]
      .indexOf(dayName.slice(0,3).toLowerCase());
    if (dayOfWeek < 0) return;
    refDate.setHours(0,0,0,0);
    refDate.setDate(refDate.getDate() + +!!excludeToday +
      (dayOfWeek - refDate.getDay() - +!!excludeToday) % 7);
    return refDate;
  }

  function getFormattedDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;

    return day + "." + month + "." + date.getFullYear();
  }
  function getFormattedDateSystem(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;

    return date.getFullYear() + "-" + month + "-" + day;
  }
  const days = [
    getNextDayOfTheWeek("mon"),
    getNextDayOfTheWeek("tue"),
    getNextDayOfTheWeek("wed"),
    getNextDayOfTheWeek("thu"),
    getNextDayOfTheWeek("fri"),
    getNextDayOfTheWeek("sat"),
    getNextDayOfTheWeek("mon"),
  ];

  const processSchedule = (d) => {
    let matrix = schedule;
    for (let k = 0; k < d.lessons.length; k++) {
      const lesson = d.lessons[k];

      let pair = -1;
      switch (lesson.start_time.split(" ")[1]) {
        case "08:30:00": pair = 0; break;
        case "10:10:00": pair = 1; break;
        case "11:50:00": pair = 2; break;
        case "13:30:00": pair = 3; break;
        case "15:10:00": pair = 4; break;
        case "16:50:00": pair = 5; break;
        case "18:30:00": pair = 6; break;
        case "20:00:00": pair = 7; break;
      }

      let dayIndex = -1;
      switch(lesson.start_time.split(" ")[0]) {
        case getFormattedDateSystem(days[0]): dayIndex = 0; break;
        case getFormattedDateSystem(days[1]): dayIndex = 1; break;
        case getFormattedDateSystem(days[2]): dayIndex = 2; break;
        case getFormattedDateSystem(days[3]): dayIndex = 3; break;
        case getFormattedDateSystem(days[4]): dayIndex = 4; break;
        case getFormattedDateSystem(days[5]): dayIndex = 5; break;
      }

      if (pair >= 0 && dayIndex >= 0) {
        matrix[pair][dayIndex] = lesson;
      }
    }
    setSchedule(matrix);
  };

  useEffect(() => {
    setSelectedDay(["mon", "tue", "wed", "thu", "fri", "sat", "sat"][new Date().getDay() - 1]);
    if (localStorage.getItem("schedule")) {
      let cachedSchedule = JSON.parse(localStorage.getItem("schedule"));
      if (Date.now() - cachedSchedule.savedTime < (1000 * 60 * 60)) {
        processSchedule(cachedSchedule.data);
        setLoading(false);
        return;
      }
    }

    fetch(`/api/schedule`)
      .then(d => {
        if (d.status < 300 && d.status >= 200) {
          return d.json();
        } else {
          return {
            error: {
              code: 4444
            }
          };
        }
      })
      .then(d => {
        if (!d.error) {
          processSchedule(d);
          localStorage.setItem('schedule', JSON.stringify({ savedTime: Date.now(), data: d }))
          setLoading(false);
        } else if (d.error.code === 4201 || d.error.code === 4101) {
          navigate("/login");
        } else if (d.error.code === 4001) {
          setError("setup");
        } else {
          setError("error");
        }
      })
      .catch(e => {
        console.warn(e);
        toast.error(e.message);
      })

  }, []);

  if (error && error !== "") {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="grid gap-4">
          <DotLottieReact
            src={error === "setup" ? animationNeedSetup : animationError}
            autoplay
            loop
            className="block h-[240px] w-[240px] mx-auto"
          />
          <div className="font-manrope text-2xl mx-auto font-semibold">
            {error === "setup" ? "Нужно кое-что настроить" : "Ошибка!"}
          </div>
          <p>{error === "setup" ? "Чтобы расписание работало, нужно настроить номер группы и номер подгруппы" : "Произошла неизвестная ошибка. Авторы проекта уже уведомлены об этом"}</p>
          {error === "setup" && (
            <div className="mx-auto">
              <button type="submit"
                      onClick={() => navigate("/schedule/settings")}
                      className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Настройки
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="grid gap-4">
          <DotLottieReact
            src={animationLoading}
            autoplay
            loop
            className="block h-[240px] w-[240px] mx-auto"
          />
          <div className="font-manrope text-2xl mx-auto font-semibold">Загрузка</div>
        </div>
      </div>
    );
  }

  const dateMonday = getMonday(new Date());
  const dateSaturday = getSaturday(new Date());
  const weekNumber = getWeekNumber(new Date())[1];

  return (
    <div className="grid gap-6">
      <div className="grid grid-cols-[1fr_2fr_1fr] gap-6 items-center">
        <div className="flex justify-end">
          {/*<button>*/}
          {/*  <ArrowLeftIcon className={"w-5 h-5"}/>*/}
          {/*</button>*/}
        </div>
        <div className="text-center">
          <div className="text-xl font-bold font-manrope">{dateMonday.getDate()} сен. - {dateSaturday.getDate()} сен.</div>
          <div className="text-sm">{weekNumber} неделя ({weekNumber % 2 === 0 ? "четная" : "нечетная"})</div>
        </div>
        <div className="flex justify-start">
          {/*<button>*/}
          {/*  <ArrowRightIcon className={"w-5 h-5"}/>*/}
          {/*</button>*/}
        </div>
      </div>

      <DaySwitcher
        currentDay={selectedDay}
        setCurrentDay={setSelectedDay}
      />

      <div className="grid gap-2 w-full p-6 lg:p-0">
        <div className={`grid lg:grid-cols-6 w-full text-center border-y border-y-zinc-300 gap-2`}>
          <div className={`p-4 text-sm font-semibold ${selectedDay === "mon" ? "" : "hidden lg:block"}`}>{getFormattedDate(getNextDayOfTheWeek("mon"))}, пн.</div>
          <div className={`p-4 text-sm font-semibold ${selectedDay === "tue" ? "" : "hidden lg:block"}`}>{getFormattedDate(getNextDayOfTheWeek("tue"))}, вт.</div>
          <div className={`p-4 text-sm font-semibold ${selectedDay === "wed" ? "" : "hidden lg:block"}`}>{getFormattedDate(getNextDayOfTheWeek("wed"))}, ср.</div>
          <div className={`p-4 text-sm font-semibold ${selectedDay === "thu" ? "" : "hidden lg:block"}`}>{getFormattedDate(getNextDayOfTheWeek("thu"))}, чт.</div>
          <div className={`p-4 text-sm font-semibold ${selectedDay === "fri" ? "" : "hidden lg:block"}`}>{getFormattedDate(getNextDayOfTheWeek("fri"))}, пт.</div>
          <div className={`p-4 text-sm font-semibold ${selectedDay === "sat" ? "" : "hidden lg:block"}`}>{getFormattedDate(getNextDayOfTheWeek("sat"))}, сб.</div>
        </div>

        {[1,2,3,4,5,6,7,8].map(pair => {
          let exists = false;
          for (let i = 0; i <= 5; i++) {
            if (schedule[pair - 1][i] !== null) {
              exists = true;
              break;
            }
          }

          if (!exists) return null;

          return(
            <div className="grid lg:grid-cols-6 w-full text-center gap-2" key={pair}>
              {[0,1,2,3,4,5].map(day => (
                <div className={["mon", "tue", "wed", "thu", "fri", "sat"][day] === selectedDay ? "" : "hidden lg:block"}>
                  <Pair data={schedule[pair - 1][day]} pair={pair} />
                </div>
              ))}
            </div>
          );
        })}
      </div>

      <div className="m-10 bg-zinc-100 p-4">
        <b>Внимание!</b> Это неофициальное приложение. Пожалуйста, сверяйте расписание с официальным приложением Мой универ.
      </div>
    </div>
  );
}
