import {Link} from "react-router-dom";
import {Menu} from "@headlessui/react";

export default function UserProfileMenuItem({ icon, text, href, ...rest }) {
  return(
    <Menu.Item>
      {({focus}) => (
        <Link
          to={href}
          className={
            focus
              ? `bg-zinc-200/25 ring-1 ring-zinc-200 grid w-full cursor-pointer grid-flow-col items-center justify-start gap-3 rounded-lg px-4 py-3 transition`
              : `grid w-full cursor-pointer grid-flow-col items-center justify-start gap-3 rounded-lg px-4 py-3 transition`
          }
          {...rest}
        >
          {icon} {text}
        </Link>
      )}
    </Menu.Item>
  );
}
