import {useState, useEffect} from "react";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import animationLoading from "./assets/animation_loading.lottie";
import toast from "react-hot-toast";

export default function Settings() {
  const [ disabled, setDisabled ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ academic, setAcademic ] = useState('');
  const [ subgroup, setSubgroup ] = useState(0);
  const [ oacademic, setoAcademic ] = useState('');
  const [ osubgroup, setoSubgroup ] = useState(0);

  const save = (e) => {
    e.preventDefault();

    if (academic.trim() === "" || subgroup < 1) {
      toast.error("Введите номер группы и номер подгруппы");
      return;
    }

    setDisabled(true);

    const fd = new FormData();
    fd.append('academic', academic);
    fd.append('subgroup', subgroup);

    fetch(`/api/edit_account`, {
      method: "POST",
      body: fd
    })
      .then(d => d.json())
      .then(d => {
        setDisabled(false);
        if (!d.error) {
          if (d.errors.includes("invalid_group")) {
            toast.error("Группа не найдена. Введите группу, например Б9124-09.03.04");
            setAcademic(oacademic);
          } else {
            setoAcademic(academic);
          }
          if (d.errors.includes("invalid_subgroup")) {
            toast.error("Подгруппа должна быть больше 0");
            setSubgroup(osubgroup);
          } else {
            setoSubgroup(subgroup);
          }
        } else {
          setSubgroup(osubgroup);
          setAcademic(oacademic);
          toast.error("Ошибка изменения информации");
        }
        window.updateUser();
      })
      .catch(e => {
        setDisabled(false)
        console.warn(e);
        toast.error(e.message);
      })
  };

  useEffect(() => {
    fetch(`/api/user`)
      .then(res => res.json())
      .then(d => {
        if (!d.error) {
          setAcademic(d.academic_group.name);
          setoAcademic(d.academic_group.name);
          setSubgroup(d.subgroup);
          setoSubgroup(d.subgroup);
          setLoading(false);
        } else {
          toast.error("Не удалось получить информацию о профиле");
        }
      })
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="grid gap-4">
          <DotLottieReact
            src={animationLoading}
            autoplay
            loop
            className="block h-[240px] w-[240px] mx-auto"
          />
          <div className="font-manrope text-2xl mx-auto font-semibold">Загрузка</div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto max-w-2xl grid gap-4">
      <h1 className="text-2xl font-manrope font-semibold">Настройки профиля</h1>
      <p>Для работы с Моим расписанием необходимо указать код академической группы (например, Б9124-09.03.04) и номер
        Вашей подгруппы.</p>

      <form className="space-y-6" action="#" method="POST" onSubmit={save}>
        <div>
          <label htmlFor="academic" className="block text-sm font-medium leading-6 text-gray-900">
            Академическая группа
          </label>
          <div className="mt-2">
            <input id="academic" name="academic" type="text" required
                   disabled={disabled} value={academic} onChange={e => setAcademic(e.target.value)}
                   className="outline-0 block rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between">
            <label htmlFor="subgroup" className="block text-sm font-medium leading-6 text-gray-900">Подгруппа</label>
          </div>
          <div className="mt-2">
            <input id="subgroup" name="subgroup" type="number" autoComplete="current-password" required
                   min={1}
                   disabled={disabled} value={subgroup} onChange={e => setSubgroup(e.target.value)}
                   className="outline-0 block rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
          </div>
        </div>

        <div>
          <button type="submit"
                  disabled={disabled}
                  className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            {disabled ? "Сохранение..." : "Сохранить"}
          </button>
        </div>
      </form>
    </div>
  );
}
