import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import animationLoading from "./assets/animation_loading.lottie";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function LogoutPage() {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem('schedule');
    window.location.href = "/api/logout";
  }, [ ]);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="grid gap-4">
        <DotLottieReact
          src={animationLoading}
          autoplay
          loop
          className="block h-[240px] w-[240px] mx-auto"
        />
        <div className="font-manrope text-2xl mx-auto font-semibold">Выход из аккаунта</div>
      </div>
    </div>
  );
}
