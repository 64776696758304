import logo from "./assets/logo-icon.png";
import downloadFromRustore from "./assets/logo-color-light.svg";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

export default function LoginPage() {
  const [ disabled, setDisabled ] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`/api/user`)
      .then(d => d.json())
      .then(d => {
        if (d.guid) {
          navigate("/schedule");
        }
      })
  }, []);

  const login = (e) => {
    e.preventDefault();

    toast.promise(
      new Promise((a, b) => {
        setDisabled(true);

        const fd = new FormData();
        fd.append("username", e.target.username.value);
        fd.append("password", e.target.password.value);

        fetch(`/api/login`, {
          method: "POST",
          body: fd
        })
          .then(d => d.json())
          .then(d => {
            if (d.success) {
              a();
              navigate(`/schedule`);
            } else {
              b("Неверные логин или пароль");
            }
            setDisabled(false);
          })
          .catch(e => {
            setDisabled(false);
            console.warn(e);
            b(e.message);
          })
      }),
      {
        loading: "Авторизация...",
        success: "Авторизация успешна!",
        error: (err) => err
      }
    )
  };

  return(
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-10 w-auto" src={logo} alt="Logo" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 font-manrope">Войти в аккаунт</h2>
        <p className="text-center text-sm mt-3">Войдите, используя учетные данные ДВФУ. Пароль мы не сохраняем.</p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST" onSubmit={login}>
          <div>
            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">Имя пользователя или
              емейл</label>
            <div className="mt-2">
              <input id="username" name="username" type="text" required
                     className="outline-0 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Пароль</label>
            </div>
            <div className="mt-2">
              <input id="password" name="password" type="password" autoComplete="current-password" required
                     className="outline-0 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
            </div>
          </div>

          <div>
            <button type="submit"
                    disabled={disabled}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              {disabled ? "Вход..." : "Войти"}
            </button>
          </div>
        </form>

        <div className="mt-10 flex justify-center gap-3">
          <img src={downloadFromRustore} alt="Download from Rustore" className="block cursor-pointer h-12"
               onClick={() => window.open("https://www.rustore.ru/catalog/app/net.edwardcode.vuz.schedule", "_blank")}/>
        </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          <a href="https://documents.dpkgsoft.com/apps/dvfu/my_schedule/site_privacy"
             className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Политика конфиденциальности</a>
        </p>
        <p className="text-sm text-gray-500 text-center">
          &copy; {new Date().getFullYear()} <a href="https://edwardcode.net">edwardcode</a><br/>
        </p>
        <p className="text-sm text-gray-500 text-center">
          <a href="https://dpkgsoft.com">DpkgSoft International Limited</a>
        </p>
      </div>
    </div>
  );
}
