import {ClockIcon, FlagIcon, UserIcon, PencilIcon, SignalIcon} from "@heroicons/react/24/outline";
import {useEffect, useState} from "react";
import PairModal from "./PairModal";
import {strToDate} from "./utils";

export default function Pair({ pair, data }) {
  const [ pairOpen, setPairOpen ] = useState(false);
  const [ time, setTime ] = useState(0);
  const [ type, setType ] = useState("current");
  const [ timePeriod, setTimePeriod ] = useState("current");

  const updateTimings = (tp) => {
    const dateStart = strToDate(data.start_time);
    const dateEnd = strToDate(data.end_time);

    let time = tp;
    let type = "pending";
    if (Date.now() > dateEnd.getTime()) {
      type = "old";
    } else if (Date.now() < dateStart.getTime() && (dateStart - 1000 * 60 * 90)) {
      time = `через ${Math.floor((dateStart.getTime() - Date.now()) / (1000 * 60))} мин.`
    } else if (Date.now() > dateStart.getTime() && Date.now() < dateEnd.getTime()) {
      type = "current";
      time = `конец через ${Math.floor((dateEnd.getTime() - Date.now()) / (1000 * 60))} мин.`
    }

    setType(type);
    setTime(time);
  };

  useEffect(() => {
    if (!data) return;

    let tp = "0:00";
    switch (pair) {
      case 1: tp = "8:30"; break;
      case 2: tp = "10:10"; break;
      case 3: tp = "11:50"; break;
      case 4: tp = "13:30"; break;
      case 5: tp = "15:10"; break;
      case 6: tp = "16:50 "; break;
      case 7: tp = "18:30"; break;
      case 8: tp = "20:10"; break;
    }

    setTimePeriod(tp);

    updateTimings(tp);
    const i = setInterval(() => updateTimings(tp), 1000 * 30);
    return () => clearInterval(i);
  }, []);

  if (!data) {
    return <div className="w-full min-h-[123px]" />
  }

  return(
    <div
      onClick={() => setPairOpen(true)}
      className={`text-left grid grid-rows-[auto_1fr] gap-[10px] w-full min-h-[123px] ${type === "old" ? "bg-zinc-50" : (type === "current" ? "bg-orange-50" : "bg-cyan-50")} rounded-xl shadow p-2 cursor-pointer ${type === "old" ? "hover:bg-zinc-100/25" : (type === "current" ? "bg-orange-50" : "hover:bg-cyan-100/25")} transition`}>
      <div className="flex flex-row items-center justify-between gap-2">
        <div className="grid grid-cols-[auto_1fr] gap-1 text-[0.65rem] items-center">
          <ClockIcon className="w-3 h-3"/>
          <span>{time}</span>
        </div>
        {data.facility !== "" && (
          <div className="grid grid-cols-[auto_1fr] gap-1 text-[0.65rem] items-center">
            <FlagIcon className="w-3 h-3"/>
            <span className="truncate break-keep">{data.facility}</span>
          </div>
        )}
      </div>
      {/* Pair Body */}
      <div className="grid grid-rows-[1fr_auto] h-full">
        <div className="font-manrope text-[0.85rem] line-[0.85rem] font-semibold max-h-[2.6rem] truncate overflow-y-hidden">
          {data.name}
        </div>
        <div className="flex flex-col gap-[5px] text-[0.65rem]">
          <div className="grid grid-cols-[auto_1fr] gap-1 text-[0.65rem] items-center">
            {data.type === "Лекционные занятия" ? (
              <SignalIcon className="w-3 h-3"/>
            ) : (
              <PencilIcon className="w-3 h-3"/>
            )}
            <span>{data.type === "Лекционные занятия" ? "Лекция" : data.type}</span>
          </div>
          {(data.teacher !== "" && data.teacher !== "БезИмени А А") && (
            <div className="grid grid-cols-[auto_1fr] gap-1 text-[0.65rem] items-center">
              <UserIcon className="w-3 h-3"/>
              <span>
                {data.teacher.split(" ")[0]}{' '}
                {data.teacher.split(" ")[1].charAt(0)}.{' '}
                {data.teacher.split(" ")[2].charAt(0)}.
              </span>
            </div>
          )}
        </div>
      </div>
      <PairModal pair={data} pairNum={pair} open={pairOpen} setOpen={setPairOpen} />
    </div>
  );
}
